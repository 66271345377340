import {ApiPaths, ApiService, SetAuthToken, ApiStatus} from '../../utils/ApiService';
import {GET_FEEDBACKS, GET_QUERIES, GET_TICKETS_FALLOWUP, SELECTED_SITE_ID, GET_TICKETS_FALLOWUP_COUNT } from './types';
import{MAIN_LOAD_TRUE, MAIN_LOAD_FALSE} from '../User/types';
import {displayMessage, displayError, mainLoadingTrue, mainLoadingFalse} from '../User/action'

import { toast } from 'react-toastify';

const UserData = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null ;

export const GetFeedback_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        if(UserData){
        const body = {
            mobileNumber : UserData["crm_customer_contact_no_one"],
            userID : UserData["crm_customer_details_id"],
            ...data
        }
        return ApiService.post(ApiPaths.GET_FEEDBACK, body)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(!data.error) {
                    dispatch(getFeedbacks(data.feedback));
                    dispatch(displayMessage(data.message));
                } else {
                    dispatch(displayError(data.message));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
        }else{
            console.log("redirect to home")
        }
    }
}


export const GetQueries_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        if(UserData){
        const body = {
            mobileNumber : UserData["crm_customer_contact_no_one"],
            userID : UserData["crm_customer_details_id"],
            ...data
        }
        return ApiService.post(ApiPaths.GET_QUERIES, body)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(!data.error) {
                    dispatch(getQueries(data.query));
                    dispatch(displayMessage(data.message));
                } else {
                    dispatch(displayError(data.message));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
        }else{
            console.log("redirect to home")
        }
    }
}


export const GetTickets_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        if(UserData){
        const body = {
            mobileNumber : UserData["crm_customer_contact_no_one"],
            userID : UserData["crm_customer_details_id"],
            ...data
        }
        return ApiService.post(ApiPaths.GET_TICKETS_FALLOWUP, body)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(!data.error) {
                    console.log(data.ticketFollowUp);
                    dispatch(getTicketFallowUp(data.ticketFollowUp));
                    dispatch(ticketsFallowupCount_action(data.totalCount))
                    dispatch(displayMessage(data.message));
                } else {
                    dispatch(displayError(data.message));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
        }else{
            console.log("redirect to home")
        }
    }
}

export const postFeedback_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        if(UserData){
            const body = {
                userID : UserData["crm_customer_details_id"],
                ...data
            }
        return ApiService.post(ApiPaths.POST_FEEDBACK, body).then(response => {
                const {data}  = response;
                dispatch(mainLoadingFalse());
                if(!data.error)
                {
                    dispatch(displayMessage(data.message)); 
                }
                else
                {
                    dispatch(displayError(data.message));
                    setTimeout(function(){ dispatch(displayError(""))}, 3000);
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
        }else{
            console.log("redirect to home")
        }
    }
}



export const postQuery_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        if(UserData){
            const body = {
                userID : UserData["crm_customer_details_id"],
                ...data
            }
        return ApiService.post(ApiPaths.POST_QUERY, body).then(response => {
                const {data}  = response;
                dispatch(mainLoadingFalse());
                if(!data.error)
                {
                    dispatch(displayMessage(data.message)); 
                }
                else
                {
                    dispatch(displayError(data.message));
                    setTimeout(function(){ dispatch(displayError(""))}, 3000);
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
        }else{
            console.log("redirect to home")
        }
    }
}

export const postFallowUp_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        if(UserData){
            const body = {
                userID : UserData["crm_customer_details_id"],
                ...data
            }
        return ApiService.post(ApiPaths.POST_FALLOWUP, body).then(response => {
                const {data}  = response;
                dispatch(mainLoadingFalse());
                if(!data.error)
                {
                    toast.success(data.message);
                    dispatch(displayMessage(data.message)); 
                }
                else
                {
                    dispatch(displayError(data.message));
                    setTimeout(function(){ dispatch(displayError(""))}, 3000);
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
        }else{
            console.log("redirect to home")
        }
    }
}

export const getFeedbacks = (data) => {
    return {
        type : GET_FEEDBACKS,
        payload : {data}
    }
}


export const getQueries = (data) => {
    return {
        type : GET_QUERIES,
        payload : {data}
    }
}

export const getTicketFallowUp = (data) => {
    return {
        type : GET_TICKETS_FALLOWUP,
        payload : {data}
    }
}

export const selectedQueryId_action= (data) => {
    return {
        type : SELECTED_SITE_ID,
        payload : {data}
    }
}

export const ticketsFallowupCount_action= (data) => {
    return {
        type : GET_TICKETS_FALLOWUP_COUNT,
        payload : {data}
    }
}