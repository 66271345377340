import axios from 'axios';

export const BaseUrl = 'https://knsonline.in/RestAPI/customer/';
export const DocumentUrl = 'https://knsonline.in/';

export const ApiPaths = {
    LOGIN: 'userLogin',
    OTPVERIFY : 'verifyOtp',
    GET_SITES: 'getMySites',
    GET_MySITEDETAILS: 'getMySiteDetail',
    GET_PROJECTS: 'getWebProject',
    GET_NOTIFICATIONS: 'getMyNotification',
    GET_MySITEPAYMEMTS: "getSitePayment",
    GET_MySITEDOCUMENTS: 'getMyDocuments',
    GET_SITEPROGRESS: 'getProjectProgressMilstone',
    GET_SITEPROGRESS_IMAGE : 'getProjectProgressImages',
    GET_FEEDBACK: 'getMyFeedback',
    POST_FEEDBACK: 'addCustomerFeedback',
    GET_CONTACTS: 'getCustomerContacts',
    GET_QUERIES: 'getMyQuery',
    POST_QUERY: 'addCustomerQuery',


    GET_TICKETS_FALLOWUP: 'getTicketFollowUp',
    POST_FALLOWUP : 'addTicketFollowUp',
    GET_NEWS: 'GetNews',
    GET_TESTS: 'GetTests',
    GET_QUESTIONS: 'GetQuestions/',
    SUBMIT_RESULT: 'SubmitTestResult',
    GET_TEST_RESULT: 'GetResult/',
    UPDATE_PROFILE: 'ProfileUpdate',
    UPLOAD_PROFILE_PIC: 'UploadProfilePic',
    CHANGE_PASSWORD: 'UpdatePassword',
    GET_ORDERS: 'GetUserOrders',
    GET_CART_DETAILS: 'GetUserCartDetails',
    GET_CART_STATUS: 'GetCartStatus',
    ADD_TO_CART: 'AddToCart',
    REMOVE_FROM_CART: 'RemoveItemFromCart',
    SAVE_CHECKOUT: 'SaveCheckout',
    PAYMENT_SUCCESS: 'PaymentSuccess',
};

export const ApiService = axios.create({
    baseURL : BaseUrl + 'v1/',
    headers : {
        'Accept' : 'application/json',
        'Content-Type' : 'application/x-www-form-urlencoded',
    }
});

export const SetAuthToken = (token) => {
    if(token)
    {
        ApiService.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
    else
    {
        delete ApiService.defaults.headers.common['Authorization'];
    }
}

export const ApiStatus = {
    OTP_SENT: 'OTP sent',
}