import {CONTACTS_SUCCESS } from './types';

const initialState = {
    contacts : []
}

const Contact_reducer = (state = initialState, { type, payload }) => {
    
    switch(type)
    {
        case CONTACTS_SUCCESS:
            return Object.assign({}, state, { contacts: payload.data });
        
        default:  
            return state;
    }
}

export default Contact_reducer;