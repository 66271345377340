import {GET_NOTIFICATION } from './types';

const initialState = {
    notifications : []
}

const Notification_reducer = (state = initialState, { type, payload }) => {
    switch(type)
    {
        case GET_NOTIFICATION:
            return Object.assign({}, state, { notifications: payload.data });
        
        default:  
            return state;
    }
}

export default Notification_reducer;