import * as React from 'react';
export const routes = [
	{
		path: '/',
		Component: React.lazy(() => import('../pages/MySite/SiteRouter')),
		exact: true,
	},
	{
		path: '/login',
		Component: React.lazy(() => import('../pages/Login')),
		exact: true,
	},
	{
		path: '/verifyOtp',
		Component: React.lazy(() => import('../pages/OTPVerify')),
		exact: true,
	},
	{
		path: '/projects',
		Component: React.lazy(() => import('../pages/Project')),
		exact: true,
	},
	{
		path: '/notifications',
		Component: React.lazy(() => import('../pages/Notification')),
		exact: true,
	},
	{
		path: '/sites',
		Component: React.lazy(() => import('../pages/MySite/SiteRouter')),
		exact: false,
	},
	{ 
		path: '/proect/details',
		Component: React.lazy(() => import('../pages/ProjectDetails')),
		exact: true,
	},
	// {
	// 	path: '/payments',
	// 	Component: React.lazy(() => import('../pages/Payments')),
	// 	exact: true,
	// },
	{
		path: '/contacts',
		Component: React.lazy(() => import('../pages/Contacts')),
		exact: true,
	},
	{
		path: '/feedback',
		Component: React.lazy(() => import('../pages/Feedback')),
		exact: true,
	},
	{
		path: '/tickets',
		Component: React.lazy(() => import('../pages/Queries/queryRouter')),
		exact: false,
	},

	
]
