import {ApiPaths, ApiService, SetAuthToken, ApiStatus} from '../../utils/ApiService';
import {GET_NOTIFICATION } from './types';
import{MAIN_LOAD_TRUE, MAIN_LOAD_FALSE} from '../User/types';
import {displayMessage, displayError, mainLoadingTrue, mainLoadingFalse} from '../User/action'
const UserData = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null ;

export const GetNotification_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        if(UserData){
        const body = {
            mobileNumber : UserData["crm_customer_contact_no_one"],
            userID : UserData["crm_customer_details_id"],
            ...data
        }
        return ApiService.post(ApiPaths.GET_NOTIFICATIONS, body)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(!data.error) {
                    dispatch(getNotification(data.notification));
                    dispatch(displayMessage(data.message));
                } else {
                    dispatch(displayError(data.message));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
        }else{
            console.log("redirect to home")
        }
    }
}

export const getNotification = (data) => {
    return {
        type : GET_NOTIFICATION,
        payload : {data}
    }
}

