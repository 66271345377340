import {ApiPaths, ApiService, SetAuthToken, ApiStatus} from '../../utils/ApiService';
import {GET_SITES , GET_SITE_DETAILS,GET_SITE_PROGRESS_IMAGE,SELECTED_PROJECT_ID, GET_SITE_PAYMENTS,GET_SITE_PROGRESS, SELECTED_SITE_ID, GET_SITE_DOCUMENTS} from './types';
import{MAIN_LOAD_TRUE, MAIN_LOAD_FALSE} from '../User/types';
import {displayMessage, displayError, mainLoadingTrue, mainLoadingFalse} from '../User/action'
import sleepTime from '../config';

const UserData = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null ;

export const GetSites_action = (data) => {
    return dispatch => {    
        dispatch(mainLoadingTrue());
        if(UserData){
        const body = {
            mobileNumber : UserData["crm_customer_contact_no_one"],
            userID : UserData["crm_customer_details_id"],
            ...data
        }
        return ApiService.post(ApiPaths.GET_SITES, body)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(!data.error) {
                    dispatch(getSites(data.site));
                    dispatch(displayMessage(data.message));
                } else {
                    dispatch(displayError(data.message));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
        }else{
            console.log("redirect to home") 
        }
        dispatch(mainLoadingFalse());
    }   
}

export const GetSiteDetails_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        if(UserData){
        const body = {
            mobileNumber : UserData["crm_customer_contact_no_one"],
            userID : UserData["crm_customer_details_id"],
            ...data
        }
        return ApiService.post(ApiPaths.GET_MySITEDETAILS, body)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(!data.error) {
                    dispatch(getSiteDetails(data.site));
                    dispatch(displayMessage(data.message));
                } else {
                    dispatch(displayError(data.message));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
        }else{
            console.log("redirect to home")
        }
        dispatch(mainLoadingFalse());
    }
}


export const GetSitePayments_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        if(UserData){
        const body = {
            mobileNumber : UserData["crm_customer_contact_no_one"],
            userID : UserData["crm_customer_details_id"],
            ...data
        }
        return ApiService.post(ApiPaths.GET_MySITEPAYMEMTS, body)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(!data.error) {
                    dispatch(getSitePayments(data.payment));
                    dispatch(displayMessage(data.message));
                } else {
                    dispatch(displayError(data.message));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
        }else{
            console.log("redirect to home")
        }
        dispatch(mainLoadingFalse());
    }
}


export const GetSiteDocuments_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        if(UserData){
        const body = {
            mobileNumber : UserData["crm_customer_contact_no_one"],
            userID : UserData["crm_customer_details_id"],
            // userID : 3200,
            ...data,
            // bookingID:3408,
        }
        return ApiService.post(ApiPaths.GET_MySITEDOCUMENTS, body)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(!data.error) {
                    dispatch(getSiteDocuments(data.document));
                    dispatch(displayMessage(data.message));
                } else {
                    dispatch(displayError(data.message));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
        }else{
            console.log("redirect to home")
        }
        dispatch(mainLoadingFalse());
    }
}


export const GetSiteProgress_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        if(UserData){
        const body = {
            mobileNumber : UserData["crm_customer_contact_no_one"],
            userID : UserData["crm_customer_details_id"],
            // userID : 3200,
            ...data,
            // bookingID:3408,
        }
        return ApiService.post(ApiPaths.GET_SITEPROGRESS, body)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(!data.error) {
                    dispatch(getSiteProgress(data.milestone));
                    dispatch(displayMessage(data.message));
                } else {
                    dispatch(displayError(data.message));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
        }else{
            console.log("redirect to home")
        }
        dispatch(mainLoadingFalse());
    }
}


export const GetSiteProgressImages_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        if(UserData){
        const body = {
            mobileNumber : UserData["crm_customer_contact_no_one"],
            userID : UserData["crm_customer_details_id"],
            // userID : 3200,
            ...data,
            // bookingID:3408,
        }
        delete body['mobileNumber']
        return ApiService.post(ApiPaths.GET_SITEPROGRESS_IMAGE, body)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if(!data.error) {
                    dispatch(getSiteProgressImages(data.milestoneImage));
                    dispatch(displayMessage(data.message));
                } else {
                    dispatch(displayError(data.message));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
        }else{
            console.log("redirect to home")
        }
        dispatch(mainLoadingFalse());
    }
}
export const getSites = (data) => {
    return {
        type : GET_SITES,
        payload : {data}
    }
}

export const getSiteDetails = (data) => {
    return {
        type : GET_SITE_DETAILS,
        payload : {data}
    }
}

export const getSitePayments= (data) => {
    return {
        type : GET_SITE_PAYMENTS,
        payload : {data}
    }
}


export const getSiteDocuments= (data) => {
    return {
        type : GET_SITE_DOCUMENTS,
        payload : {data}
    }
}

export const getSiteProgress= (data) => {
    return {
        type : GET_SITE_PROGRESS,
        payload : {data}
    }
}



export const getSiteProgressImages= (data) => {
    return {
        type : GET_SITE_PROGRESS_IMAGE,
        payload : {data}
    }
}

export const selectedSiteId_action= (data) => {
    return {
        type : SELECTED_SITE_ID,
        payload : {data}
    }
}

export const selectedProjectId_action= (data) => {
    return {
        type : SELECTED_PROJECT_ID,
        payload : {data}
    }
}
