export const MAIN_LOAD_TRUE = 'MAIN_LOAD_TRUE';
export const MAIN_LOAD_FALSE = 'MAIN_LOAD_FALSE';
export const SET_AUTH_USER = 'SET_AUTH_USER';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const DISPLAY_MESSAGE = 'DISPLAY_MESSAGE';
export const DISPLAY_ERROR = 'DISPLAY_ERROR';
export const SUCCESS_STATUS = 'SUCCESS_STATUS';
export const OTP_SENT_SUCCESS = 'OTP_SENT_SUCCESS';
export const OTP_VALID_SUCCESS = 'OTP_VALID_SUCCESS';
export const OTP_VALID_FAILS = 'OTP_VALID_FAILS';



