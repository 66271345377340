import React, { Component } from 'react';
import { withStyles } from "@mui/styles";
import {Button, Grid, CardMedia, TextField, Box} from '@mui/material';

import OtpInput from "react-otp-input";
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { compose } from 'recompose'
import {otpVerify_action} from '../../../redux/User'


const useStyles = theme => ({
    root: {
           flexGrow: 1,
           backgroundColor: "#DFE3E6",
    },
    login : {
        height:"100vh",
        backgroundColor: "#DFE3E6",
        textAlign : "center",
        overflow: "hidden"
        // overflowX : "hidden",
        // overflowY : "hidden"
    },
    loginText : {
        color : "#393939",
        fontFamily : "Nunito Sans",
        fontSize : "25px",
        fontWeight : "bold"
    },
    loginContent : {
        color : "#707070",
        fontFamily : "Regular",
        fontSize : "14px",
        "& span"  :{
            width : "300px"
        }
    },
    icon : {
        marginTop : "12% !important"
    },
    homeIcon : {
        width  : "128px",
        height : "150px"
    },
    GridBody : {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width  : "95% !important",
        margin : "10px"
    },
    Grid : {
        marginTop : "22px !important"
    },
    GridItem : {
        width: "335px",
        color : "#000",
        margin : "64px 5px 0 5px",
        background: "#FFF",
        height: "600px",
        paddingTop: "24px",
        borderRadius : "33px 33px 0 0", 

        "& .MuiOutlinedInput-input" :
         {
            padding: "11.5px 14px",
            outline : "0px !important",
            color:"#575656"
        },
        "& .Mui-focused" : {
            outline : "0px !important",
            color:"#575656", 
            border : "#575656 !important"
        },
        '& .MuiOutlinedInput-root': {
            fontSize : "14px",
            '&.Mui-focused fieldset': {
              borderColor: '#575656',
              borderSize: "1px",
              borderWidth: "thin"
            },
          },
          '&.Mui-focused fieldset': {
            borderColor: '#575656',
            borderWidth: "thin"
          },
          "& input" : {
            width  : "250px"
          },
         

    },
    button : {
        marginTop : "59px",
        textAlign : "center",
        "& button" : {
            backgroundColor : "#ffcd00",
            width  : "300px",
            borderRadius: "20px",
            color : "#FFF !important",
            fontWeight : "bold",
            '&:hover': {
                background: "#9e9e9e",
             },
            [theme.breakpoints.down('md')]: {
                width  : "200px"    
              },
        },
        [theme.breakpoints.up('md')]: {
            marginTop : "29px",
          },
    },
    otpBox : {
        display: "flex",
        justifyContent: "space-around",
        "& input" : {
            width : "3em !important",
            height : "3em"
        }
    },
    otpContent : {
        marginTop : "22px"
    },
    errorOTP : {
        display: "flex",
        justifyContent: "space-around",
        color : "#e57373"
    }
    
  });

class OTPVerify extends Component {

    constructor(props){
        super(props)
        this.state = {
            mobileNumber : "",
            otp : [],
            error : null,
            showPage : false
        }
    }
    componentDidMount(){
        if(this.props.location.state){
            this.setState({
                mobileNumber : this.props.location.state,
                showPage : true
            })
        }
        // else {
        //     this.setState({
        //         showPage : false
        //     })
        // }
    }

    handleChange = otp => { 
        const re = /^[0-9\b]+$/;
      if (otp === '' || re.test(otp)) {
        this.setState({ otp })
      }
    };

    OTPConfirm = async() =>{
        await this.props.otpVerify({
            mobileNo : this.state.mobileNumber,
            userOtp : this.state.otp
        })
        const {valid_otp} = this.props;
           if(valid_otp){
            this.props.history.push({
                pathname : "/",
                   state : ""
               });
               window.location.reload();
           }else{
                this.setState({
                    error : "Enter Correct OTP"
                })
                return false;
           }
    }
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.login}>
                {this.state.showPage && 
            <Grid container>
                <Grid className={classes.icon} item xs={12} >
                    <Box className={classes.loginText}>Verification</Box>
                    <Box className={classes.loginContent} >
                        <span>Please enter the verification code sent to this phone number</span>
                    </Box>
                </Grid>
                <Grid item className={classes.Grid} xs={12} justifyContent="center">
                    <Grid className={classes.GridBody} container  justifyContent="center" >
                        {/* <Grid item style={{backgroundColor : "#FFFFFF"}}> */}
                        <Grid item xs={12} sm={6} md={5} className={classes.GridItem} >
                           <Box p={2}>
                           <Box className={classes.loginContent}>OTP Sent to</Box>
                            <Box className={classes.loginText}>{this.state.mobileNumber}</Box>
                            </Box>

                            <Box  p={1} className={classes.otpContent}>
                                <Box p={1} className={classes.loginContent}>Enter OTP here</Box>
                                <Box className={classes.otpBox}>
                                <OtpInput
                                    value={this.state.otp}
                                    onChange={this.handleChange}
                                    numInputs={4}
                                    separator={<span>-</span>}
                                    isInputNum={true}
                                    shouldAutoFocus={true}
                                />
                                </Box>
                                <Box className={classes.errorOTP} >
                                    {this.state.error}
                                </Box>
                            </Box>
                            <Box className={classes.button}> <Button variant="outlined" onClick={() => this.OTPConfirm()}>Confirm</Button> </Box>
                            <Box p={2} style={{textAlign : "center"}} className={classes.loginContent}> <span>Did't receive otp? <a href={"#"}>Change the Number</a></span> </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> }
          </div>
      
        );
    }
}

const mapStatesToProps = (state) => {
	return {
		valid_otp: state.user.valid_otp
	}
}
const mapActionsToProps = {
	otpVerify : otpVerify_action
}

export default compose(
    connect(mapStatesToProps, mapActionsToProps),
    withStyles(useStyles),
    withRouter
  )(OTPVerify)