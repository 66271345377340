import {ApiPaths, ApiService, SetAuthToken, ApiStatus} from '../../utils/ApiService';
import {MAIN_LOAD_TRUE, MAIN_LOAD_FALSE, SET_AUTH_USER,
     OTP_SENT_SUCCESS, OTP_VALID_FAILS, LOGIN_SUCCESS, DISPLAY_MESSAGE, 
     DISPLAY_ERROR,OTP_VALID_SUCCESS} from './types';
import { toast } from 'react-toastify';

export const userRegister_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.LOGIN, data).then(response => {
                const {data}  = response;
                dispatch(mainLoadingFalse());
                if(!data.error)
                {
                    // dispatch(displayMessage(data.message));
                    toast.success(data.message);
                    // setTimeout(function(){ dispatch(displayMessage(""))}, 3000);
                    dispatch(otpSentSuccess());
                }
                else
                    {
                        toast.error(data.message);
                        // dispatch(displayError(data.message));
                        // setTimeout(function(){ dispatch(displayError(""))}, 3000);
                    }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}

export const otpVerify_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        localStorage.clear()
        dispatch(otpSentfails());
        return ApiService.post(ApiPaths.OTPVERIFY, data).then(response => {
                const {data}  = response;
                dispatch(mainLoadingFalse());
                if(!data.error)
                {
                    // dispatch(displayMessage(data.message));
                    toast.success(data.message);
                    localStorage.setItem('user', JSON.stringify(data.user));
                    dispatch(otpValidSuccess());
                    // setTimeout(function(){ dispatch(displayMessage(""))}, 3000);
                }
                else{
                    toast.error(data.message);
                    dispatch(otpSentfails());
                    // dispatch(displayError(data.message));
                    // setTimeout(function(){ dispatch(displayError("")); }, 3000);
                }
            })
            .catch(err => {
                dispatch(otpSentfails());
                dispatch(mainLoadingFalse());
                displayError('Failed, try again later');
            })
    }
}

export const mainLoadingTrue = () => {
    return {
        type : MAIN_LOAD_TRUE
    }
}
export const mainLoadingFalse = () => {
    return {
        type : MAIN_LOAD_FALSE
    }
}

export const displayMessage = (message) => {
    return {
        type : DISPLAY_MESSAGE,
        payload : {
            message : message
        }
    }
}

export const otpSentSuccess = () => {
    return {
        type : OTP_SENT_SUCCESS
    }
}

export const otpSentfails = () => {
    return {
        type : OTP_VALID_FAILS
    }
}



export const otpValidSuccess = () => {
    return {
        type : OTP_VALID_SUCCESS
    }
}


export const setAuthUser = (auth_user) => {
    return {
        type : SET_AUTH_USER,
        payload : {
            auth_user : auth_user
        }
    }
}

export const displayError = (error) => {
    return {
        type : DISPLAY_ERROR,
        payload : { 
            error : error
        }
    }
}