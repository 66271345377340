import {GET_SITES , GET_SITE_DETAILS, GET_SITE_PROGRESS_IMAGE,SELECTED_PROJECT_ID, GET_SITE_PAYMENTS,GET_SITE_PROGRESS, SELECTED_SITE_ID, GET_SITE_DOCUMENTS} from './types';

const initialState = {
    sites : [],
    siteDetails : {},
    sitePayments : [],
    siteDocuments : [],
    siteProgress : [],
    siteProgressImages : [],
    SiteBookingId : "",
    SiteProjectId : "",
}

const Site_reducer = (state = initialState, { type, payload }) => {
    switch(type)
    {
        case GET_SITES:
            return Object.assign({}, state, { sites: payload.data });
        case GET_SITE_DETAILS:
                return Object.assign({}, state, { siteDetails: payload.data });
        case GET_SITE_PAYMENTS:
            return Object.assign({}, state, { sitePayments: payload.data });
        case SELECTED_SITE_ID:
            return Object.assign({}, state, { SiteBookingId: payload.data });  
        case SELECTED_PROJECT_ID:
            console.log(payload.data, "from Site_reducer")
            return Object.assign({}, state, { SiteProjectId: payload.data });
        case GET_SITE_DOCUMENTS:
            return Object.assign({}, state, { siteDocuments: payload.data });
        case GET_SITE_PROGRESS:
            return Object.assign({}, state, { siteProgress: payload.data });     
        case GET_SITE_PROGRESS_IMAGE:
            return Object.assign({}, state, { siteProgressImages: payload.data });    
        default:  
            return state;
    }
}

export default Site_reducer;