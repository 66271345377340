import React, {useState} from 'react';
import logo from './logo.svg';
import {makeStyles} from '@mui/styles';
import './App.scss';
import {connect} from 'react-redux';
import {Routing} from './components/routers';
import {Snackbar, CircularProgress, SnackbarContent} from "@mui/material"
import MuiAlert from '@mui/material/Alert';
// import { useSnackbar } from 'notistack';
import ErrorBoundary from './components/ErrorBoundary'

import { ToastContainer, toast } from 'react-toastify';
// import { getToken, onMessageListener } from './utils/firebase';

import 'react-toastify/dist/ReactToastify.css';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({

  loader : {
    display: "flex",
    justifyContent: "center",
    height: "100vh",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    left: "50%",
    "&:after": {
      content:'',
    display:"block",
    position:"absolute",
    left:"48%",
    top:"40%",
    width:"40px",
    height:"40px",
    borderStyle:"solid",
    borderColor:"black",
    borderTopColor:"transparent",
    borderWidth: "4px",
    borderRadius:"50%",
    "-webkit-animation": "spin .8s linear infinite",
    animation: "spin .8s linear infinite"
      }
  }
  
}));

function App(props) {
  const classes = useStyles();
  const {message, error} =  props;
  // const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const customId = "custom-id-yes";
  const toastId = React.useRef(null)

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({title: '', body: ''});
  const [isTokenFound, setTokenFound] = useState(false);
  // getToken(setTokenFound);

  // onMessageListener().then(payload => {
  //   setShow(true);
  //   console.log(isTokenFound)
  //   toast("new Notification");
  //   setNotification({title: payload.notification.title, body: payload.notification.body})
  //   console.log(payload);
  // }).catch(err => console.log('failed: ', err));

  if(message){
    // toast.dismiss();    
    // toast.success(message, {
    //   // toastId: customId
    // }) 
    // if(! toast.isActive(toastId.current)) {
    //   toastId.current = toast(message);
    // }
}
  else if(error){ 
    // toast.error(message, {
      
    // })
    if(! toast.isActive(toastId.current)) {
      toastId.current = toast(message);
    }
  }

  // toastId: customId 
  return (
    <div className="App">
     {props.main_loader &&  <div className={classes.loader}>
      <CircularProgress  color="secondary" /> 
    </div>
 }
  <ToastContainer 
  position="top-center"
  closeOnClick
  autoClose={1500}
  pauseOnHover
  limit={2}
  />
  {/* <ErrorBoundary> */}
  <Routing />  
  {/* </ErrorBoundary> */}
    </div>
  );
}

const mapStatesToProps = (state) => {
	return {
		message: state.user.message,
    error : state.user.error,
    main_loader : state.user.main_loader
  }
}
export default connect(mapStatesToProps, null)(App);