import {GET_FEEDBACKS, GET_QUERIES, GET_TICKETS_FALLOWUP, SELECTED_SITE_ID, GET_TICKETS_FALLOWUP_COUNT } from './types';

const initialState = {
    feedbacks : [],
    queries : [],
    ticketfallowups : [],
    ticketfallowupsCount : 0,
    selectedQuery : {},
}

const Feedback_reducer = (state = initialState, { type, payload }) => {
    
    switch(type)
    {
        case GET_FEEDBACKS:
            return Object.assign({}, state, { feedbacks: payload.data });
        case GET_QUERIES:
            return Object.assign({}, state, { queries: payload.data });
        case GET_TICKETS_FALLOWUP:
            return Object.assign({}, state, { ticketfallowups: payload.data });
        case GET_TICKETS_FALLOWUP_COUNT:
            return Object.assign({}, state, { ticketfallowupsCount: payload.data });
        case SELECTED_SITE_ID:
            return Object.assign({}, state, { selectedQuery: payload.data });
        default:  
            return state;
    }
}

export default Feedback_reducer;