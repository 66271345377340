import {GET_PROJECTS } from './types';

const initialState = {
    projects : []
}

const Project_reducer = (state = initialState, { type, payload }) => {
    
    switch(type)
    {
        case GET_PROJECTS:
            return Object.assign({}, state, { projects: payload.data });
        
        default:  
            return state;
    }
}

export default Project_reducer;