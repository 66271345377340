import React, { Component } from 'react';
import { withStyles } from "@mui/styles";
import {Button, Grid, CardMedia, TextField, Box} from '@mui/material';
import Image from '../../../Image/KNS-icon.png';
import hand from '../../../Image/hand.png';
import {connect} from 'react-redux';
import {userRegister_action} from '../../../redux/User'
import {withRouter} from 'react-router-dom';
import { compose } from 'recompose'

import classNames from 'classnames';

const useStyles = theme => ({
    root: {
           flexGrow: 1,
           backgroundColor: "#DFE3E6",
    },
    login : {
        // height:"100vh",
        backgroundColor: "#DFE3E6",
        textAlign : "center",
        height: "100vh",
        overflow : "hidden"
        // overflowX : "hidden",
        // overflowY : "hidden"
    },
    loginText : {
        color : "#393939",
        fontFamily : "Nunito Sans",
        fontSize : "25px",
        fontWeight : "bold"
    },
    loginContent : {
        color : "#707070",
        fontFamily : "Regular",
        fontSize : "14px",
        marginBottom : "20px"
    },
    icon : {
        marginTop : "62px"
    },
    homeIcon : {
        width  : "128px",
        height : "150px",
        marginBottom: "15px",
        marginTop : "70px"
    },
    GridBody : {
        // backgroundColor : "#FFFFFF",
        // borderRadius : "33px 33px 0 0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // width: "335px",
        margin: "5px 10px 0px 10px",
        "& .Mui-focused" : {
            color : "#393939 !important",
        }
        
    },  
    Grid : {
        width: "335px",
        // height : "388px",
        display: "flex",
        marginTop: "15px",
        height: "333px"
    },
    GridItem : {
        width: "335px",
        color : "#000",
        marginTop : "64px",
        background: "#FFF",
        height: "600px",
        paddingTop: "64px",
        borderRadius : "33px 33px 0 0",
        "& .MuiOutlinedInput-input" :
         {
            padding: "11.5px 14px",
            // minWidth : "275px",
            // minHeight : "65px",
            outline : "0px !important",
            color:"#575656"
        },
        "& .Mui-focused" : {
            outline : "0px !important",
            color:"#575656", 
            border : "#575656 !important"
        },
        '& .MuiOutlinedInput-root': {
            fontSize : "14px",
            '&.Mui-focused fieldset': {
              borderColor: '#575656',
              borderSize: "1px",
              borderWidth: "thin"
            },
          },
          '&.Mui-focused fieldset': {
            borderColor: '#575656',
            borderWidth: "thin"
          },
          "& input" : {
            width  : "250px"
          },
         "& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button":
          { 
           "-webkit-appearance": "none",
            margin: "0"
          }
    },
    button : {
        marginTop : "59px",
        textAlign : "center",   
        "& button" : {
            backgroundColor : "#ffcd00",
            width  : "300px",
            fontWeight : "bold",
            borderRadius: "20px",
            color : "#FFF !important",
            '&:hover': {
                background: "#9e9e9e",
             },
            [theme.breakpoints.down('md')]: {
                width  : "200px"    
            },
        }
    }
    
  });

class Login extends Component {

    constructor(props){
        super(props)
        this.state = {
            mobileNumber : "",
            error : null
        }
    }

    componentDidMount () {
        // localStorage.clear()
    }
    
    getOTP = async() =>{
        const phoneNo = this.state.mobileNumber
        
        if(phoneNo.length == 10){
           await  this.props.userRegister({mobileNo : this.state.mobileNumber})
           const {otp_sent} = this.props;
           if(otp_sent){
            this.props.history.push({
                pathname : "/verifyOtp",
                   state : this.state.mobileNumber
               });
           }
        }
        else{
            this.setState({
                error : "Please Enter Correct Mobile Number"
            })
        }
        
    } 

    numberChange  = (e) =>{
        const {name, value} = e.target;
        this.setState({
            [name] : value
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.login}>
            <Grid container>
                <Grid className={classes.icon} item xs={12} >
                    <img
                        className={classes.homeIcon}
                        src={Image}
                        title="Contemplative Reptile"
                    />
                    <Box className={classes.loginText}>Login</Box>
                    <Box className={classes.loginContent}>Please enter your details</Box>
                </Grid>
                <Grid item className={classes.Grid} xs={12} >
                    <Grid container className={classes.GridBody}  justifyContent="center"  >
                        <Grid item xs={12} sm={6} className={classes.GridItem} >
                            <Box>
                                <TextField
                                    required    
                                    type="number"
                                    name="mobileNumber"
                                    id="outlined-required"
                                    label={<b>Mobile Number</b>}
                                    variant="outlined"
                                    autoFocus={true}
                                    InputLabelProps={{ shrink: true }}
                                    defaultValue={this.state.mobileNumber}
                                    onChange = {(e) =>this.numberChange(e)}
                                    error={this.state.error}
                                />
                                </Box>
                               <Box className={classes.button}> <Button variant="outlined" onClick={() => this.getOTP()}>Get OTP</Button> </Box>
                            </Grid>
                    </Grid>
                </Grid>
            </Grid>
          </div>
      
        );
    }
}

const mapStatesToProps = (state) => {
	return {
		otp_sent: state.user.otp_sent
	}
}
const mapActionsToProps = {
	userRegister : userRegister_action
}
export default compose(
    connect(mapStatesToProps, mapActionsToProps),
    withStyles(useStyles),
    withRouter
  )(Login)