import React, {useState} from 'react';
import { alpha, makeStyles } from '@mui/styles';
import {AppBar, Box, List, ListItem, ListItemText, Divider, ListItemIcon, Drawer} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AlarmIcon from '@mui/icons-material/Alarm';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import AddchartIcon from '@mui/icons-material/Addchart';
import classNames from "classnames";
import Image from '../../../Image/KNS-icon.png';
import {Link} from 'react-router-dom'

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  grow: { 
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    display: 'none !important', 
    [theme.breakpoints.down('sm')]: {
      display: 'block !important', 
    },
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inline-flex',
    },
  },
  title2 :{
    display: 'inline-flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  listName: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '38% !important',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    [theme.breakpoints.up('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width:'76% !important',
      display: 'flex',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(3),
      width: '76% !important',
      display: 'flex',
    }, 
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(3),
      width: '60% !important',
      display: 'flex',
    }, 
  },
  listitem:{
    color : '#000',
    display: "flex",
    alignItems: "center",
    "& a" : {
      color : '#000 !important',
      textDecoration: "none"
    }
},
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  hide: {
    display: "none"
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    marginTop:"90px",
    [theme.breakpoints.up('sm')]: {
      display : "none"
    },
  },
  drawerOpen: {
    marginTop:"56px",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    marginTop:"56px",
    display: "none",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    }
  },
  toolbar : { 
    "& a" : {
      textDecoration : "none",
      color : "inherit"
    }
  }
}));

export default function Navbar() {
  
  const [open, setOpen] = useState(false)
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleLogOut = () =>{
    localStorage.clear()
    window.location.reload()
  }
  const handleDrawerOpen = () => {
    setOpen(!open)
  };


  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(false)
  };


  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={() => handleLogOut()}>Log Out</MenuItem>
    </Menu>
  );  
  const renderSidebar = (
    <Drawer
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}
          open={open}
          onClose={toggleDrawer("anchor", "false")}
        >
          <div className={classes.toolbar} >
          <List onClick={handleDrawerOpen}>
             <ListItem button key={"Home"} >
                <ListItemIcon><HomeOutlinedIcon/></ListItemIcon>
                <Link to="/"><ListItemText primary={"Home"} /></Link>
              </ListItem> 
              <ListItem button key={"Projects"}>
                <ListItemIcon><RoomOutlinedIcon/></ListItemIcon>
                <Link to="/projects"><ListItemText primary={"Projects"} /></Link>
              </ListItem>
              <ListItem button key={"My Site"}>
                <ListItemIcon><LockOutlinedIcon/></ListItemIcon>
                <Link to="/sites"><ListItemText primary={"My Site"} /></Link>
              </ListItem>
              <ListItem button key={"Notification"}>
                <ListItemIcon><AlarmIcon/></ListItemIcon>
                <Link to="/notifications"><ListItemText primary={"Notification"} /></Link>
              </ListItem>
              <ListItem button key={"Feedback"}>
                <ListItemIcon><TouchAppOutlinedIcon/></ListItemIcon>
                <Link to="/feedback"><ListItemText primary={"Feedback"} /></Link>
              </ListItem>
              <ListItem button key={"Contact"}>
                <ListItemIcon><PersonIcon/></ListItemIcon>
                <Link to="/contacts"><ListItemText primary={"Contact"} /></Link>
              </ListItem>
              <ListItem button key={"Ticket"}>
                <ListItemIcon><AddchartIcon/></ListItemIcon>
                <Link to="/tickets"><ListItemText primary={"Ticket"} /></Link>
              </ListItem>
              <ListItem button key={"logout"}>
                <ListItemIcon><ExitToAppOutlinedIcon/></ListItemIcon>
                <ListItemText primary={"Logout"} onClick={()=> handleLogOut()}/>
              </ListItem>
          </List>
          </div>
        </Drawer>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <Typography className={classes.title} variant="h6"  style={{ alignItems: "center" }} noWrap>
          <img src={Image} height={50} style={{ padding: "0px 8px" }} />  KNS Infrastracture
      </Typography>
      <AppBar position="static" style={{ background: '#ffcd00' }} >
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={()=>handleDrawerOpen()}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title2} variant="h6"  style={{ textAlign: "center" }} noWrap>
            KNS Infrastracture
          </Typography>
          <Box ml={2} className={classes.listName} style={{ align: "center" }}>
            <Typography className={classes.listitem} variant="h7" noWrap>
            <HomeOutlinedIcon/> <Link to="/">Home</Link>
            </Typography>
            <Typography className={classes.listitem} variant="h7" noWrap>
              <RoomOutlinedIcon/> <Link to="/projects">Projects</Link>
            </Typography>
            <Typography className={classes.listitem} variant="h7" noWrap>
              <LockOutlinedIcon /><Link to="/sites"> My Site</Link>
            </Typography>
            {/* <Typography className={classes.listitem} variant="h7" noWrap>
              <AlarmIcon /><Link to="/notification">Notification</Link>
            </Typography> */}
            <Typography className={classes.listitem} variant="h7" noWrap>
              <TouchAppOutlinedIcon /> <Link to="/feedback">Feedback</Link>
            </Typography>
            <Typography className={classes.listitem} variant="h7" noWrap>
              <PersonIcon /> <Link to="/contacts">Contact</Link>
            </Typography>
            <Typography className={classes.listitem} variant="h7" noWrap>
              <AddchartIcon /> <Link to="/tickets">Tickets</Link>
            </Typography>
          </Box>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge color="secondary">
              <Link to="/notifications"><NotificationsIcon /></Link>
              </Badge>
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderSidebar}
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}