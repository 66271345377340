import React from 'react';
import { Switch, HashRouter, Route, BrowserRouter} from 'react-router-dom';
import { routes } from './routes';
import PrivateRoute from './PrivateRoute';
import Navbar from '../../components/common/Navbar';
import Login from '../pages/Login';
import OTPVerify from '../pages/OTPVerify'

export const Routing = ({children}) => {
	const noAuth = localStorage.getItem("user")
	return (
		<HashRouter>
				{/* {authUser ? <>
						<Navbar />
						<div style={{padding : "2%"}}> */}
						<Switch >
							{routes.map(({ path, Component, exact }) => (
								<PrivateRoute
									path={path}
									key={path}
									exact={exact}
									noAuth={noAuth}
									component={Component}
								/>
							))}
							{children}
						</Switch>
						{/* </div>
					</>
					:
					<Login />
				} */}
		</HashRouter>
	);
};
