import { createStore, combineReducers, applyMiddleware , compose} from 'redux';
import thunk from 'redux-thunk'
import {User_reducer} from './User'
import {Site_reducer} from './Sites'
import {Project_reducer} from './Projects'
import {Notification_reducer} from './Notification'
import {Feedback_reducer} from './Feedback'
import {Contact_reducer} from './Contacts'

const All_reducers = combineReducers({
    user: User_reducer,
    site : Site_reducer,
    project : Project_reducer,
    notification : Notification_reducer,
    feedback : Feedback_reducer,
    contact : Contact_reducer
});


const store = createStore(All_reducers, 
    compose(
        applyMiddleware(thunk),
        window.devToolsExtension ? window.devToolsExtension() : f => f
        )
    );

export default store;